import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"

const SuccessContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  flex-direction: column;
  margin-top: 10rem;
  margin-bottom: 5rem;
`

/* Success Header */
const SuccessHeaderContainer = styled.div`
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
`

const SuccessTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
`

const SuccessLine = styled.div`
  background-color: #7798ab;
  width: 1rem;
  margin: 0 auto;
  height: 0.1rem;
  margin-top: 0.5rem;
`

/* Success Link Styles */
const SuccessLink = styled.a`
  color: #ededed;
  background-color: black;
  padding: .75rem 1.25rem;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-size: 1.25rem;
  -webkit-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: #ededed;
    border: 1px solid black;
    color: black;
    -webkit-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
  }
`

const SuccessPage = () => (
  <Layout>
    <SEO title="Form Successful" />
    <SuccessContainer>
        <SuccessHeaderContainer>
            <SuccessTitle>Success</SuccessTitle>
            <SuccessLine>&nbsp;</SuccessLine>
        </SuccessHeaderContainer>
      <h2>Thank you for subscribing!</h2>
      <SuccessLink href="/">Head Home</SuccessLink>
    </SuccessContainer>
  </Layout>
)

export default SuccessPage
